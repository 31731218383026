<template>
  <div v-show="shown" class="pwa-install">
    <v-btn x-small depressed @click="clickInstall"
      >{{ $ml.get("button.installPWA") }}
      <v-icon right dark>mdi-tray-arrow-down</v-icon>
    </v-btn>

    <portal to="pwa-tips">
      <div v-if="isShowIosTips" class="pwa-install__tips">
        <div class="pwa-install__tips-back" @click="isShowIosTips = false" />
        <div class="pwa-install__tips-content">
          <p>
            1. {{ $ml.get("pwa.iosTips.1") }}<br /><br />
            <img
              src="~@/assets/img/ios-share-btn.png"
              width="60"
              class="pwa-install__tips-img"
            />
          </p>
          <v-divider />
          <p>
            2. {{ $ml.get("pwa.iosTips.2") }}<br /><br />
            <img
              src="~@/assets/img/ios-add-home-screen-ru.png"
              class="pwa-install__tips-img"
            />
          </p>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import platform from "platform-detect";

export default {
  data: () => ({
    shown: false,
    isShowIosTips: false,
  }),

  beforeMount() {
    if (platform.ios) {
      this.shown = true;
    } else {
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        this.installEvent = e;
        this.shown = true;
      });
    }
  },

  methods: {
    clickInstall() {
      if (platform.ios) {
        this.isShowIosTips = true;
      } else {
        this.installPWA();
      }
    },

    installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        if (choice.outcome === "accepted") {
          this.shown = false;
        }
      });
    },
  },
};
</script>
